import React, { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';

import { SiteMetaDataType, useSiteMetadata } from '@/hooks/useSiteMetadata';
import { isTestEnv } from '@/utils/envHelper';
import SkipToA11y from '@/components/shared/SkipTo.a11y';
import useTranslations from '@/hooks/useTranslations';
import useIsTablet from '@/hooks/useIsTablet';
import {
  LOGIN_ID,
  MAIN_CONTENT_ID,
  MAIN_NAVIGATION_ID,
} from './shared/htmlIds.contants';

type Props = {
  children: ReactNode;
};

const DefaultLayout: React.FC<Props> = ({ children }) => {
  const { t } = useTranslations();
  const isTablet = useIsTablet();
  const siteMetadata: SiteMetaDataType = useSiteMetadata();
  const cdnUrl = siteMetadata.cdnUrl;

  // set var on window to allow CommandersAct to target NPW next to old public website
  if (typeof window !== 'undefined') {
    window['KTPW'] = true;
  }

  return (
    <>
      <Helmet>
        <meta content='strict-origin' name='referrer' />
        <link
          rel='preload'
          href={`${cdnUrl}fonts/averta-regular-webfont.woff2`}
          as='font'
          type='font/woff2'
          crossOrigin=''
        />
        <link
          rel='preload'
          href={`${cdnUrl}fonts/averta-regularitalic-webfont.woff2`}
          as='font'
          type='font/woff2'
          crossOrigin=''
        />
        <link
          rel='preload'
          href={`${cdnUrl}fonts/averta-light-webfont.woff2`}
          as='font'
          type='font/woff2'
          crossOrigin=''
        />
        <link
          rel='preload'
          href={`${cdnUrl}fonts/averta-semibold-webfont.woff2`}
          as='font'
          type='font/woff2'
          crossOrigin=''
        />
        <link
          rel='preload'
          href={`${cdnUrl}fonts/averta-bold-webfont.woff2`}
          as='font'
          type='font/woff2'
          crossOrigin=''
        />
        <link
          rel='preload'
          href={`${cdnUrl}fonts/averta-extrabold-webfont.woff2`}
          as='font'
          type='font/woff2'
          crossOrigin=''
        />
        <style>{`
          /* Font-face declarations */
          @font-face {
              font-family: 'Averta';
              src: url('${cdnUrl}fonts/averta-regular-webfont.woff2') format('woff2'),
                    url('${cdnUrl}fonts/averta-regular-webfont.woff') format('woff'),
                    local('Averta');
          }
          @font-face {
            font-family: 'Averta Italic';
            src: url('${cdnUrl}fonts/averta-regularitalic-webfont.woff2') format('woff2'),
                  url('${cdnUrl}fonts/averta-regularitalic-webfont.woff') format('woff'),
                  local('Averta Italic');
          }
          @font-face {
              font-family: 'Averta Light';
              src: url('${cdnUrl}fonts/averta-light-webfont.woff2') format('woff2'),
                    url('${cdnUrl}fonts/averta-light-webfont.woff') format('woff'),
                    local('Averta Light');
          }
          @font-face {
              font-family: 'Averta SemiBold';
              src: url('${cdnUrl}fonts/averta-semibold-webfont.woff2') format('woff2'),
                    url('${cdnUrl}fonts/averta-semibold-webfont.woff') format('woff'),
                    local('Averta SemiBold');
          }
          @font-face {
              font-family: 'Averta Bold';
              src: url('${cdnUrl}fonts/averta-bold-webfont.woff2') format('woff2'),
                    url('${cdnUrl}fonts/averta-bold-webfont.woff') format('woff'),
                    local('Averta Bold');
          }
          @font-face {
              font-family: 'Averta ExtraBold';
              src: url('${cdnUrl}fonts/averta-extrabold-webfont.woff2') format('woff2'),
                    url('${cdnUrl}fonts/averta-extrabold-webfont.woff') format('woff'),
                    local('Averta ExtraBold');
          }

          /* CSS Mini Reset */
          html, body, div, form, fieldset, legend, label, h1, h2, h3, h4, h5, h6, p {
            background: none;
            margin: 0;
            padding: 0;
          }
          html, body {
            height: 100%;
            width: 100%;
          margin: 0;
          }
          html {
            font-size: 10px;
            box-sizing: border-box;
            scroll-behavior: smooth;
          }
          body {
            font-family: Averta, sans-serif;
            font-size: 1.0rem;
            box-sizing: border-box;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
          *, *:before, *:after {
            box-sizing: inherit;
          }
          a:hover {
            cursor:pointer;
          }
          i {
            font-style: normal;
          }
          tt, code, kbd, samp {
            font-family: inherit;
          }
          @media (max-width: 799px){
            #wonderchat-wrapper {
              height: calc(100% - 72px) !important;
            }
          }
        `}</style>
        {/* POC: Dynatrace RUM on TEST only */}
        {/* !!! DISABLED FOR NOW AS THERE ARE TECHNICAL ISSUES WITH DYNATRACE RUM !!! */}
        {isTestEnv && (
          <script
            async
            defer // old browser support
            src={
              'https://monitoring.test.keytrade.com:443/jstag/managed/65151a7d-ec02-4602-a96d-a75f87653125/91ff9de381c67f22_complete.js'
            }
            type='text/javascript'
          />
        )}
        <script
          src={`${cdnUrl}scripts/survey.min.js`}
          type='text/javascript'
          async
          defer // old browser support
        />
        <script
          src={`${cdnUrl}scripts/matomo.js`}
          type='text/javascript'
          async
          defer // old browser support
        />
        <script
          src={`${cdnUrl}scripts/ca.js`}
          type='text/javascript'
          async
          defer // old browser support
        />
      </Helmet>
      <ThemeProvider
        theme={{
          fonts: {
            regular: '"Averta", sans-serif',
            semiBold: '"Averta SemiBold", sans-serif',
            bold: '"Averta Bold", sans-serif',
            extraBold: '"Averta ExtraBold", sans-serif',
            light: '"Averta Light", sans-serif',
            italic: '"Averta Italic", sans-serif',
          },
          type: 'public',
        }}
      >
        <>
          {!isTablet && (
            <SkipToA11y
              id={MAIN_NAVIGATION_ID}
              label={t('skip.toNavigation')}
            />
          )}
          {!isTablet && <SkipToA11y id={LOGIN_ID} label={t('skip.toLogin')} />}
          <SkipToA11y id={MAIN_CONTENT_ID} label={t('skip.toContent')} />
          {children}
        </>
      </ThemeProvider>
    </>
  );
};

export default DefaultLayout;
