/*
 * These breakpoints values are inspired from the Bootstrap breakpoints
 * To construct media queries, generally prefer the utility functions in ./helpers
 * Keep in mind that these are just some default values, you can also use custom media queries if your component layout breaks at a specific viewport
 */
export const BREAKPOINTS = {
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
};

export const PHONE_NUMBER = '+32 2 679 90 00';
export const EMAIL_ADDRESS = 'info@keytradebank.com';

export const FACEBOOK = 'https://www.facebook.com/KeytradeBank';
export const TWITTER = 'https://twitter.com/keytradebank';
export const YOUTUBE = 'https://www.youtube.com/user/KeytradeBank';
export const INSTAGRAM = 'https://www.instagram.com/keytrade_bank/';
export const LINKEDIN = 'https://www.linkedin.com/company/keytrade-bank';

export const CUSTOMER_COOKIE = 'KEYcustomer';
export const LOGIN_COOKIE = 'KEYLoginMethod';

export const TOPBLOCK_SELECTOR_LOCAL_STORAGE = 'KEYtopblockselector';

export const INTERNAL_LINK = 'ContentfulInternalLink';
export const EXTERNAL_LINK = 'ContentfulExternalLink';

export const NR_OF_ARTICLES_PER_BLOG_PAGE = 6;
export const NR_OF_ARTICLES_PER_TAG_PAGE = 8;

export const LANGUAGE_STORAGE_KEY = 'stored-language';

export enum COOKIE_CATEGORIES {
  REQUIRED = 'req',
  FUNCTIONAL = 'func',
  ANALYTIC = 'anal',
  ADVERTISING = 'ad',
  SOCIAL = 'soc',
}
