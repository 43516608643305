import styled from 'styled-components';

type ContainerProps = {
  fluid?: boolean;
  narrow?: boolean;
};
const Container = styled.div<ContainerProps>`
  max-width: ${(props) =>
    props.fluid ? 'none' : props.narrow ? '776px' : '1160px'};
  width: 100%;
  padding: 0 1.6rem;
  margin: 0 auto;
`;

export default Container;
