import React from 'react';
import styled from 'styled-components';

import { config } from '@keytrade/functions';

const { list: colors } = config.colors;

type Props = {
  id: string;
  label: string;
};

const Link = styled.a`
  position: absolute;
  top: 0.8rem;
  left: 0.8rem;
  color: ${colors.White};
  font-size: 1.8rem;
  font-weight: bold;
  background-color: ${colors.BlueSky};
  border-radius: 0.6rem;
  text-decoration: none;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);

  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  z-index: -1;

  &:focus,
  &:focus-visible {
    clip: auto !important;
    -webkit-clip-path: none;
    clip-path: none;
    height: auto;
    width: auto;
    padding: 0.8rem 1.4rem;
    margin: 0;
    z-index: 100000;
  }
`;

const SkipToA11y: React.FC<Props> = (props) => {
  const { id, label } = props;

  return <Link href={`#${id}`}>{label}</Link>;
};

export default SkipToA11y;
