import { useState, useEffect } from 'react';
import window from 'global';
import { BREAKPOINTS } from '@/utils/constants';
export const getIsTablet = (): boolean =>
  window && window.innerWidth <= BREAKPOINTS.LG;

export default function useIsTablet(): boolean {
  const [isTablet, setIsTablet] = useState(getIsTablet());

  useEffect(() => {
    const onResize = () => {
      setIsTablet(getIsTablet());
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return isTablet;
}
