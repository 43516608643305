import { useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

type AnyObject = Record<string, unknown>;

type TranslationFunctions = {
  t: <K extends AnyObject>(content: string, ...innerContentKeys: K[]) => string;
  to: <T, K extends AnyObject>(
    label: string,
    value?: T,
    otherProperties?: K,
  ) => {
    label: string;
    value: string | T;
  } & K;
  formatted: typeof FormattedMessage;
};

interface LabelizedObject {
  label: string;
}

const useTranslations = (prefixKey?: string): TranslationFunctions => {
  const intl = useIntl();
  const prefix = prefixKey ? `${prefixKey}.` : '';

  const functions = useMemo(() => {
    function getTranslation<K extends AnyObject>(
      content: string,
      ...innerContentKeys: K[]
    ): string;
    function getTranslation<T extends LabelizedObject, K extends AnyObject>(
      content: T,
      ...innerContentKeys: K[]
    ): T;
    function getTranslation(content: any, ...innerContentKeys: any[]) {
      const isContentObject = typeof content === 'object';
      const key = isContentObject ? content.label : content;

      const translation = intl.formatMessage(
        {
          id: `${prefix}${key}`,
        },
        ...innerContentKeys,
      );

      if (isContentObject) {
        return {
          ...content,
          label: translation,
        };
      }

      return translation;
    }

    const getTranlatedObject = <T, K extends AnyObject>(
      label: string,
      value?: T,
      otherProperties?: K,
    ) =>
      getTranslation({
        label,
        value: value ?? label,
        ...otherProperties,
      });

    return {
      t: getTranslation,
      to: getTranlatedObject,
      FormattedMessage,
    };
  }, [prefix, intl]);

  return functions;
};

export default useTranslations;
