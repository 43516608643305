import { useStaticQuery, graphql } from 'gatsby';

export type SiteMetaDataType = {
  title: string;
  siteUrl: string;
  cdnUrl: string;
  description: string;
  author: string;
};

export const useSiteMetadata = (): SiteMetaDataType => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            title
            siteUrl
            cdnUrl
            description
            author
          }
        }
      }
    `,
  );
  return site.siteMetadata;
};
